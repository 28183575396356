/**
 * This file contains utilities for using client hints for user preference which
 * are needed by the server, but are only known by the browser.
 */
import { useRevalidator } from "@remix-run/react"
import * as React from "react"
import {getHintUtils} from "@epic-web/client-hints";
import {subscribeToSchemeChange} from "@epic-web/client-hints/color-scheme";
import { clientHint as timezoneHint } from "@epic-web/client-hints/time-zone"

const hintsUtils = getHintUtils({
	timezone: timezoneHint,
	// add other hints here
})

export const { getHints } = hintsUtils

/**
 * @returns inline script element that checks for client hints and sets cookies
 * if they are not set then reloads the page if any cookie was set to an
 * inaccurate value.
 */
export function ClientHintCheck({ nonce }: { nonce: string }) {
	const { revalidate } = useRevalidator()
	React.useEffect(
		() => subscribeToSchemeChange(() => revalidate()),
		[revalidate],
	)

	return (
		<script
			nonce={nonce}
			dangerouslySetInnerHTML={{
				__html: hintsUtils.getClientHintCheckScript(),
			}}
		/>
	)
}
