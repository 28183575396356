import * as Sentry from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError
} from "@remix-run/react";
import {LinksFunction, LoaderFunctionArgs, } from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";
import {GeneralErrorBoundary} from "~/component/error-boundary";
import {ReactNode, useEffect} from "react";
import {buildBrowserEnv, } from "~/service/env.server";
import {initAnalyticsBrowser} from "~/helper/analytics.browser";
import useRootData from "~/hook/useRootData";
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { getHoneypot } from './helper/honeypot.helper.server'
import {HeadersFunction, json,} from "@remix-run/server-runtime";
import {combineHeaders, getDomainUrl} from "~/helper/misc";
import {BrowserEnvApiObject} from "~/apiobject/env.apiobject";
import {ClientHintCheck, getHints} from "./util/client-hints";
import {getToast, Toast} from "./util/toast.server";
import {ClientHintsValue} from "@epic-web/client-hints";
import {useToast} from "~/component/ui/use-toast";
import { Toaster } from "./component/ui/toaster";
import { useNonce } from "./util/nonce-provider";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  // preconnect google fonts
  { rel: "preconnect", href: "https://fonts.googleapis.com/", crossOrigin: "anonymous", },
];

export type RootLoaderData =  {
  DOMAIN_URL: string;
  browserEnv: BrowserEnvApiObject;
  toast: Toast,
  honeyProps: unknown;
  requestInfo: {
    hints: ClientHintsValue<{ timezone: string }>;
    origin: string;
    path: string;
    userPreferences: TODO;
  },
}


export async function loader({ request }: LoaderFunctionArgs) {
  const honeyProps = getHoneypot().getInputProps();
  const { toast, headers: toastHeaders } = await getToast(request);

  const requestInfo = {
    hints: getHints(request),
    origin: getDomainUrl(request),
    path: new URL(request.url).pathname,
    userPreferences: {
    },
  }

  return json({
      DOMAIN_URL: getDomainUrl(request),
      browserEnv: buildBrowserEnv(),
      toast,
      honeyProps,
      requestInfo,
    },
    {
      headers: combineHeaders(
        // { 'Server-Timing': timings.toString() },
        toastHeaders,
      ),
    },
  );
}

export const headers: HeadersFunction = () => ({
  "Cache-Control": "max-age=3600",
});

export function Layout({ children }: { children: ReactNode }) {
  const { DOMAIN_URL} = useRootData();
  const disableIndexing = !DOMAIN_URL || !DOMAIN_URL.startsWith("https://jayneapp.com");
  const nonce = useNonce()

  return (
    <html lang="en">
    <head>
      <ClientHintCheck nonce={nonce} />

      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>

      {!disableIndexing ? null : (
        <meta name="robots" content="noindex, nofollow"/>
      )}

      {/*<!-- Open Graph / Facebook -->*/}
      <meta property="og:image" content={`${DOMAIN_URL}/img/coach-icon.png`}/>

      {/*<!-- Twitter -->*/}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:image" content={`${DOMAIN_URL}/img/coach-icon.png`}/>

      <meta name="theme-color" content="#2e3df5"/>

      <link rel="icon" href={`${DOMAIN_URL}/favicon.ico`}/>

      <Meta/>
      <Links/>
    </head>
    <body>

    {children}

    <ScrollRestoration nonce={nonce}/>
    <Scripts nonce={nonce}/>

    </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  // TODO: check if sentry is enable
  Sentry.captureRemixErrorBoundaryError(error);
  return <GeneralErrorBoundary />
};

function App() {
  const { browserEnv, toast} = useLoaderData<typeof loader>();
  const { toast: showToast } = useToast();

  useEffect(() => {
    if (toast) {
      showToast({
        title: toast.title,
        description: toast.description,
        variant: toast.variant,
      });
    }
  }, [toast, showToast]);

  useEffect(() => {
    initAnalyticsBrowser(browserEnv.AMPLITUDE_BROWSER_API_KEY);
  }, []);

  return (
    <>
      <Outlet />

      <Toaster />

      {/* Make env data available on window directly */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.browserEnv = ${JSON.stringify(browserEnv)}`,
        }}
      />
    </>
  )
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  )
}

export default Sentry.withSentry(AppWithProviders)
